<template>
  <CustomerComp />
</template>


<script>
export default {
  name: "Customer",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Consumer", path:"/customer", isActive: true}]
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  components: {
    CustomerComp: () => import("../../src/components/customers/CustomerComp"),
  },
};
</script>
